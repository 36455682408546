import React, { useState, useEffect, useRef } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBTypography,
  MDBInputGroup,
  // MDBScrollbar,
} from "mdb-react-ui-kit";
import { Tab, Nav } from "react-bootstrap";
import axios from "axios";
import API_URL from "../api/config";
import { useAuthNavigation } from "../Component/CheckLocalStorage";

export default function GroupMessage() {
  useAuthNavigation()
  const [groups, setGroups] = useState([]);
  const [profile, setProfile] = useState([]);
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [messageFile, setMessageFile] = useState(null);
  const messagesContainerRef = useRef(null);
  const [isUserScrolledUp, setIsUserScrolledUp] = useState(false);

  const AUTH_TOKEN = localStorage.getItem("netfronttoken");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${API_URL}update-profile/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });

        setProfile(response?.data);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchProfile();
  }, [AUTH_TOKEN]);

  useEffect(() => {
    const joinGroups = async () => {
      try {
        await axios.get(`${API_URL}join-chat-room/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });
      } catch (error) {}
    };

    joinGroups();
  }, [AUTH_TOKEN]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${API_URL}update-profile/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });

        setProfile(response?.data);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchProfile();
  }, [AUTH_TOKEN]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${API_URL}rooms/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });

        let data = response?.data;

        // Filter the data based on the condition
        const filteredGroups = data.filter(
          (group) => group.sexuality === profile.sexuality
        );

        // Set the filtered groups to the state
        setGroups(filteredGroups);
        setLoadingGroups(false);
      } catch (error) {
        console.error("Error fetching groups:", error);
        // setErrorGroups(error);
        setLoadingGroups(false);
      }
    };

    fetchGroups();
  }, [AUTH_TOKEN, profile]);

  useEffect(() => {
    const getChat = async () => {
      let groupId = groups?.[0]?.id;
      try {
        const response = await axios.get(
          `${API_URL}group_messages/${groupId}/`,
          {
            headers: {
              Authorization: `Token ${AUTH_TOKEN}`,
            },
          }
        );
        setMessages(response.data);
      } catch (error) {}
    };

    getChat();
    const intervalId = setInterval(() => {
      getChat();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [AUTH_TOKEN, groups]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedFormats.includes(file.type)) {
        setMessageFile(file);
      } else {
        alert("Invalid file format. Please choose a PNG, JPEG, or JPG file.");
      }
    }
  };

  const handleMessageChange = (e) => {
    setMessageText(e.target.value);
  };

  const handleSendMessage = async () => {
    let groupId = groups?.[0]?.id;
    try {
      if (messageText.trim() === "" && !messageFile) {
        return;
      }
      const formData = new FormData();
      if (!messageFile) {
        formData.append("content", messageText);
      }
      if (messageFile) {
        formData.append("image", messageFile);
      }
      const response = await axios.post(
        `${API_URL}group_messages/${groupId}/`,
        formData,
        {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
            "content-type": "multipart/form-data",
          },
        }
      );
      setMessageText("");
      setMessageFile(null);
    } catch (error) {
      console.error("Error sending message:", error);
      // Handle the error as needed
    }
  };

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;

    // Check if the user has scrolled up manually
    if (!isUserScrolledUp && messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [messages, isUserScrolledUp]);

  const handleScroll = () => {
    const messagesContainer = messagesContainerRef.current;

    // Check if the user has scrolled up manually
    setIsUserScrolledUp(
      messagesContainer.scrollTop <
        messagesContainer.scrollHeight - messagesContainer.clientHeight
    );
  };

  return (
    <MDBContainer fluid className="py-5" style={{ backgroundColor: "#000000" }}>
      <MDBRow>
        <MDBCol md="12">
          <MDBCard id="chat3" style={{ borderRadius: "15px" }}>
            <MDBCardBody>
              <MDBRow>
                <MDBCol md="3" lg="5" xl="4" className="mb-4 mb-md-0">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link>GROUPS</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <MDBTypography listUnStyled className="mb-0">
                    {groups.map((group) => (
                      <li key={group.id} className="p-2 border-bottom">
                        <a href="#!" className="d-flex justify-content-between">
                          <div className="d-flex flex-row">
                            {/* Adjust the content based on your API response */}
                            <div>
                              <img
                                // src={group.avatar}
                                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                                alt="avatar"
                                className="d-flex align-self-center me-3"
                                width="60"
                              />
                            </div>
                            <div className="pt-1">
                              <p className="fw-bold mb-0">
                                {group?.name || "name"}
                              </p>
                              <p
                                className="small text-muted"
                                style={{
                                  textUnderlineOffset: "none",
                                }}
                              >
                                {group?.members?.length + " members"}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                    ))}
                  </MDBTypography>
                </MDBCol>

                <MDBCol md="9" lg="7" xl="8">
                  <div style={{ textAlign: "center" }}>
                    {/* <h3>Group Name</h3> */}
                  </div>

                  <div
                    ref={messagesContainerRef}
                    onScroll={handleScroll}
                    className="p-3 scroll"
                    style={{ height: "50vh", overflowY: "auto" }}
                  >
                    {messages.map((message) => (
                      <>
                        <div
                          key={message?.id}
                          className={`d-flex flex-row ${
                            message?.sender === profile?.id
                              ? "justify-content-end"
                              : "justify-content-start"
                          }`}
                        >
                          <span
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {/* <p>{message?.sender_first_name}</p> */}
                            <img
                              src={message?.sender_profile_picture}
                              alt="avatar"
                              style={{
                                width: "45px",
                                height: "45px",
                                borderRadius: "10rem",
                              }}
                            />
                          </span>
                          <div>
                            <p
                              className={`small p-2 ms-3 mb-1 rounded-3 ${
                                message?.sender === profile?.id
                                  ? "bg-primary text-white"
                                  : "bg-light"
                              }`}
                            >
                              {message?.group_chat_image ? (
                                <img
                                  src={message?.group_chat_image}
                                  style={{ height: "20vh", width: "100%" }}
                                  alt="group chat image"
                                />
                              ) : (
                                <div>{message?.content}</div>
                              )}
                            </p>
                            <p
                              className={`small ms-3 mb-3 rounded-3 text-muted ${
                                message?.sender === profile?.id
                                  ? "float-end"
                                  : ""
                              }`}
                            >
                              {new Date(message?.timestamp).toLocaleString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }
                              )}
                            </p>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>

                  <form encType="multipart/form-data">
                    <div className="text-muted d-flex justify-content-start align-items-center pe-3 pt-3 mt-2">
                      <img
                        src={profile?.profile_picture1}
                        alt="profile picture"
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "6rem",
                        }}
                      />
                      {messageFile ? (
                        <>
                          <span className="ms-2">
                            {messageFile.name} uploaded!
                          </span>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm ms-2"
                            onClick={() => {
                              setMessageFile(null); // Clear the selected file
                            }}
                          >
                            X
                          </button>
                        </>
                      ) : (
                        <>
                         
                           <textarea
                            className="form-control form-control-lg"
                            id="exampleFormControlInput2"
                            placeholder="Type message"
                            value={messageText}
                            onChange={handleMessageChange}
                            rows={1} // You can adjust the number of rows as needed
                          ></textarea>
                          <label
                            htmlFor="fileInput"
                            className="ms-1 text-muted"
                          >
                            <MDBIcon fas icon="paperclip" />
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            className="form-control form-control-lg ms-1"
                            id="fileInput"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                        </>
                      )}
                      <a
                        className="ms-3"
                        href="#!"
                        onClick={() => {
                          handleSendMessage();
                          setMessageText(""); // Clear the message input
                        }}
                      >
                        <MDBIcon fas icon="paper-plane" />
                      </a>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
