import { React, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Image,
  ListGroup,
  Button,
  Form,
  Carousel,
  Spinner, Alert
} from "react-bootstrap";
import Rating from "../Rating";
import axios from "axios";
import API_URL from "../../api/config";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

function SelectedUserScreen() {
  const { id } = useParams();
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const navigate = useNavigate();
  const [profileId, setProfileId] = useState("");
  const [babeList, setBabeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reportReason, setReportReason] = useState("");
  const [showReportInput, setShowReportInput] = useState(false);
  const AUTH_TOKEN = localStorage.getItem("netfronttoken");
  const [messageText, setMessageText] = useState(""); // State for custom message
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}update-profile/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });
        setProfileId(response?.data?.id);

      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchUserDetails();
  }, [AUTH_TOKEN]);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting user location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
    }
  }, []);

  useEffect(() => {
    if (latitude !== null && longitude !== null) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${API_URL}get_profiles_within_radius/${latitude}/${longitude}/`,
            {
              headers: {
                Authorization: `Token ${AUTH_TOKEN}`,
              },
            }
          );
          setBabeList(response.data);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching babes:", error);
          setError(error);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [latitude, longitude, AUTH_TOKEN]);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status" variant="primary" />
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="text-center mt-5">
        {error.message}
      </Alert>
    );
  }

  const handleSendMessage = async (recipientId) => {
    try {
      setSendMessageLoading(true); // Set loading state to true

      const response = await axios.post(
        `${API_URL}direct_messages/${recipientId}/`,
        { content: messageText || "Hello" }, // Use custom message if provided, else use default message
        {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
            "content-type": "multipart/form-data",
          },
        }
      );

      // navigate("/message");
      toast.success("Messaged");
    } catch (error) {
      // console.error("Error sending message:", error);
      toast.error("Failed");
    } finally {
      setSendMessageLoading(false); // Set loading state to false after request completes
    }
  };

  const handleReport = async () => {
    try {
      setReportLoading(true); // Set loading state to true

      // Validate if the report reason is not empty
      if (!reportReason) {
        // You can display an error message or prevent the report if the reason is empty
        return;
      }

      // Your API endpoint for reporting
      const reportEndpoint = `${API_URL}report/`;

      // Prepare the report data
      const reportData = {
        reason: reportReason,
        reported_user_profile_id: id,
      };

      // Send the report request
      const response = await axios.post(reportEndpoint, reportData, {
        headers: {
          Authorization: `Token ${AUTH_TOKEN}`,
        },
      });

      // Handle the response as needed (e.g., show a success message)
      toast.success("Report sent successfully");

      // Optionally, reset the report reason and hide the input
      setReportReason("");
      setShowReportInput(false);
    } catch (error) {
      toast.error("Error sending report");
      // Handle the error as needed
    } finally {
      setReportLoading(false); // Set loading state to false after request completes
    }
  };

  return (
    <div>
      <ToastContainer />
      <Link to="/" className="btn btn-dark my-3">
        Back
      </Link>
      {babeList
        .filter((babe) => babe?.id === id)
        .map((babe) => (
          <Row key={babe.id}>
            <Col md={4} sm={12}>
              <Carousel>
                <Carousel.Item>
                  <Image
                    src={babe?.profile_picture1}
                    alt={babe?.username}
                    style={{ width: "100%", height: "20rem" }}
                    className="rounded"
                  ></Image>
                </Carousel.Item>
                <Carousel.Item>
                  <Image
                    src={
                      babe?.profile_picture2 ||
                      "https://bootdey.com/img/Content/avatar/avatar7.png"
                    }
                    alt={babe?.username}
                    style={{ width: "100%", height: "20rem" }}
                    className="rounded"
                  ></Image>
                </Carousel.Item>
                <Carousel.Item>
                  <Image
                    src={
                      babe?.profile_picture3 ||
                      "https://bootdey.com/img/Content/avatar/avatar7.png"
                    }
                    alt={babe?.username}
                    style={{ width: "100%", height: "20rem" }}
                    className="rounded"
                  ></Image>
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col md={4}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h3>Hi, I am {babe?.first_name + " " + babe.last_name}</h3>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Rating
                    value={babe?.rating}
                    text={babe?.num_reviews}
                    color={"#f8e825"}
                  />
                </ListGroup.Item>
                <ListGroup.Item>About Me: {babe?.bio}</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={4}>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col>Price: </Col>
                      <Col>
                        <strong> ₦{babe?.price}</strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Status: </Col>
                      <Col style={{ textTransform: "capitalize" }}>
                        {babe?.availability_status}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Form.Control
                     as="textarea"
                     rows={2} // You can adjust the number of rows as needed
                      type="text"
                      placeholder="Enter custom message"
                      value={messageText}
                      onChange={(e) => setMessageText(e.target.value)}
                    />
                    <Button
                      className="btn-block"
                      style={{ marginTop: "1rem" }}
                      disabled={sendMessageLoading || babe.available === "No"}
                      type="button"
                      onClick={() => handleSendMessage(babe.id)}
                    >
                      {sendMessageLoading ? "Sending..." : "Send a message"}
                    </Button>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {/* Show/hide the report input */}
                    {showReportInput ? (
                      <>
                       
                        <Form.Control
                          as="textarea"
                          rows={1} // You can adjust the number of rows as needed
                          placeholder="Enter report reason"
                          value={reportReason}
                          style={{ marginBottom: "1rem" }}
                          onChange={(e) => setReportReason(e.target.value)}
                        />
                        <Button
                          className="btn-block"
                          disabled={reportLoading}
                          type="button"
                          onClick={handleReport}
                        >
                          {reportLoading ? "Reporting..." : "Report"}
                        </Button>
                      </>
                    ) : (
                      <Button
                        className="btn-block"
                        type="button"
                        style={{ marginTop: "1rem" }}
                        onClick={() => setShowReportInput(true)}
                      >
                        Report
                      </Button>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
        ))}
    </div>
  );
}

export default SelectedUserScreen;
