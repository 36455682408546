import React from "react";
import { Card, Button } from "react-bootstrap"; // Assuming you have Bootstrap for styling

const UpdateCard = () => {
  return (
    <Card style={{ margin: "1rem 0" }}>
      <Card.Body>
        <Card.Title>Update Your Account Profile</Card.Title>
        <Card.Text>
          Welcome to NetflingandChill, for visibility on the platorm, please get verified.


        </Card.Text>
        <a href="/profile">
          <Button variant="primary">Update/Verify Profile</Button>
        </a>
      </Card.Body>
    </Card>
  );
};

export default UpdateCard;
