import React from "react";
import { Card, Button } from "react-bootstrap"; // Assuming you have Bootstrap for styling

const VerificationCard = () => {
  return (
    <Card style={{ margin: "1rem 0" }}>
      <Card.Body>
        <Card.Title>Not Verified</Card.Title>
        <Card.Text>
          This mean other users cannot see your profile. <br /> This is either
          because your verification upload is not authentic or we are yet to
          verify you.
        </Card.Text>
        {/* <Button variant="primary">Start Verification</Button> */}
      </Card.Body>
    </Card>
  );
};

export default VerificationCard;
