import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import API_URL from "../../api/config";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useAdminAuthNavigation } from '../../Component/CheckLocalStorage';

const AdminChatControl = () => {
  useAdminAuthNavigation()
  const [rooms, setRooms] = useState([]);
  const [newRoomName, setNewRoomName] = useState('');
  const [newRoomSexuality, setNewRoomSexuality] = useState('');
  const AUTH_TOKEN = localStorage.getItem("netfronttoken");

  useEffect(() => {
    // Fetch all created rooms
    const fetchRooms = async () => {
      try {
        const response = await axios.get(`${API_URL}rooms/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });
        setRooms(response.data);
      } catch (error) {
        console.error('Error fetching rooms:', error);
      }
    };

    fetchRooms();
  }, []);

  const handleCreateRoom = async () => {
    try {
      await axios.post(
        `${API_URL}create-chat-room/`,
        {
          name: newRoomName,
          sexuality: newRoomSexuality,
        },
        {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        }
      );
      // After creating the room, fetch the updated list of rooms
     window.location.reload()
    } catch (error) {
      toast.error('Error creating room');
    }
  };

  const handleUpdateRoomName = async (roomId, currentName) => {
    try {
      const updatedName = prompt('Enter new room name:', currentName);

      if (updatedName !== null) {
        await axios.put(
          `${API_URL}create-chat-room/${roomId}/`,
          {
            name: updatedName,
          },
          {
            headers: {
              Authorization: `Token ${AUTH_TOKEN}`,
            },
          }
        );
        // After updating the room name, fetch the updated list of rooms
        window.location.reload()
      }
    } catch (error) {
      toast.error('Error updating room name');
    }
  };

  return (
    <Container fluid>
      <ToastContainer/>
      <Row>
        <Col>
          <h2>Create Room</h2>
          <Form>
            <Form.Group controlId="formRoomName">
              <Form.Label>Room Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter room name"
                value={newRoomName}
                onChange={(e) => setNewRoomName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formRoomSexuality">
              <Form.Label>Sexuality</Form.Label>
              <Form.Control
                as="select"
                value={newRoomSexuality}
                onChange={(e) => setNewRoomSexuality(e.target.value)}
              >
                   <option value="create room">Create Room</option>
                   <option value="lesbian">Lesbian</option>
                 <option value="gay">Gay</option>
                 <option value="bisexual">Bisexual</option>
                <option value="heterosexual">Heterosexual</option>
              </Form.Control>
            </Form.Group>

            <Button variant="primary" onClick={handleCreateRoom}>
              Create Room
            </Button>
          </Form>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h2>Room List</h2>
          <div style={{ overflowX: 'auto' }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>No. Members</th>
                <th>Name</th>
                <th>Sexuality</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rooms.map((room) => (
                <tr key={room.id}>
                  <td>{room?.members?.length}</td>
                  <td>
                    <Form.Control
                      type="text"
                      value={room.name}
                      onChange={(e) => e.preventDefault()}
                    />
                  </td>
                  <td>{room.sexuality}</td>
                  <td>
                    <Button
                      variant="warning"
                      onClick={() => handleUpdateRoomName(room.id, room.name)}
                    >
                      Update Name
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminChatControl;
