import Table from "react-bootstrap/Table";
import { Card, Form, Button } from "react-bootstrap";
import API_URL from "../../api/config";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAdminAuthNavigation } from "../../Component/CheckLocalStorage";

function UserRequest() {
  useAdminAuthNavigation()
  const AUTH_TOKEN = localStorage.getItem("netfronttoken");
  const [users, setUsers] = useState([]);
  const [selectedSexuality, setSelectedSexuality] = useState("All");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}unverified-profiles`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching babes:", error);
      }
    };

    fetchData();
  }, [AUTH_TOKEN]);

  const handleSexualityChange = (e) => {
    setSelectedSexuality(e.target.value);
  };

  const filteredUsers =
    selectedSexuality === "All"
      ? users?.unverified
      : users?.unverified?.filter(
          (user) => user?.sexuality === selectedSexuality
        );

  const verifiedUsers = filteredUsers?.filter(
    (user) => user?.is_verified == false
  );
  const handleToggleVerification = async (userId) => {
    try {
      await axios.post(`${API_URL}toggle_user/${userId}/`, null, {
        headers: {
          Authorization: `Token ${AUTH_TOKEN}`,
        },
      });
      // Refresh the user data after toggling verification
      window.location.reload()
    } catch (error) {
      console.error("Error toggling verification:", error);
    }
  };
  const handleViewIdentity = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  return (
    <>
      <h1 className="text-center">Unverified Users</h1>
      <div style={{ width: "10%" }} className="mb-3">
        <Form.Select
          aria-label="Default select example"
          onChange={handleSexualityChange}
          value={selectedSexuality}
        >
          <option value="All">All</option>
          <option value="lesbian">Lesbian</option>
          <option value="gay">Gay</option>
          <option value="bisexual">Bisexual</option>
          <option value="heterosexual">Heterosexual</option>
        </Form.Select>
      </div>

      <Table striped bordered hover responsive style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Gender</th>
            <th>Interest</th>
            <th>Status</th>
            <th>Verify</th>
            <th>Username</th>
            <th>Identity</th>
          </tr>
        </thead>
        <tbody>
          {verifiedUsers?.map((user, index) => (
            <tr key={index}>
              <td> {index + 1} </td>
              <td>{user?.first_name + " " + user?.last_name}</td>
              <td>{user?.gender}</td>
              <td>{user?.sexuality}</td>
              <td>{user?.availability_status}</td>
              <td>
                <Form.Check
                  type="switch"
                  id={`flexSwitchCheckDefault-${index}`}
                  checked={user?.is_verified}
                  onChange={() => handleToggleVerification(user?.id)}
                  />
              </td>
                  <td>{user?.username}</td>
              <td>
                <Button
                  className="gradient-custom-4 m-1"
                  onClick={() => handleViewIdentity(user?.identity_document)}
                >
                  View
                </Button>
              </td>
              {/* <td>
                <Button className="gradient-custom-4 m-1">View</Button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default UserRequest;
