import React, { useState, useEffect, useRef } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBTypography,
  MDBInputGroup,
} from "mdb-react-ui-kit";
import { Nav } from "react-bootstrap";
import axios from "axios";
import API_URL from "../api/config";
import { useAuthNavigation } from "../Component/CheckLocalStorage";

export default function Message() {
  useAuthNavigation()
  const [chats, setChats] = useState([]);
  const [profile, setProfile] = useState([]);
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [messageFile, setMessageFile] = useState(null);
  const messagesContainerRef = useRef(null);
  const [isUserScrolledUp, setIsUserScrolledUp] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const [abortController, setAbortController] = useState(null);

  const AUTH_TOKEN = localStorage.getItem("netfronttoken");

  useEffect(() => {
    // Cleanup function to abort the ongoing request when component unmounts
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${API_URL}update-profile/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });

        setProfile(response?.data);
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    };

    fetchProfile();
  }, [AUTH_TOKEN]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${API_URL}update-profile/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });

        setProfile(response?.data);
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    };

    fetchProfile();
  }, [AUTH_TOKEN]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${API_URL}user-chat-list/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });

        let data = response?.data;
        setChats(data);
        setLoadingGroups(false);
      } catch (error) {
        console.error("Error fetching chats:", error);
        setLoadingGroups(false);
      }
    };

    fetchGroups();
    // Set up interval for periodic fetch
    const intervalId = setInterval(() => {
      fetchGroups();
    }, 5000); // 5000 milliseconds = 5 seconds

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [AUTH_TOKEN, profile]);

  const handleViewMessage = async (userId) => {
    setCurrentUserId(userId);

    // Check if there's an ongoing request and abort it
    if (abortController) {
      abortController.abort();
    }

    // Create a new AbortController for the current request
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    try {
      // Fetch messages for the new user
      const response = await axios.get(`${API_URL}direct_messages/${userId}/`, {
        headers: {
          Authorization: `Token ${AUTH_TOKEN}`,
        },
        // Signal for the request
        signal: newAbortController.signal,
      });

      // Update messages only if the request was not aborted
      setMessages(response.data);
    } catch (error) {
      // Handle errors or ignore if the request was aborted
      if (error.name !== "AbortError") {
        console.error("Error fetching messages:", error);
      }
    }
  };

  useEffect(() => {
    const getChat = async () => {
      let groupId = chats?.[0]?.id;
      try {
        const response = await axios.get(
          `${API_URL}direct_messages/${currentUserId}/`,
          {
            headers: {
              Authorization: `Token ${AUTH_TOKEN}`,
            },
          }
        );
        setMessages(response.data);
      } catch (error) {}
    };

    getChat();
    const intervalId = setInterval(() => {
      getChat();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [AUTH_TOKEN, currentUserId]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedFormats.includes(file.type)) {
        setMessageFile(file);
      } else {
        alert("Invalid file format. Please choose a PNG, JPEG, or JPG file.");
      }
    }
  };

  const handleMessageChange = (e) => {
    setMessageText(e.target.value);
  };

  const handleSendMessage = async () => {
    let groupId = chats?.[0]?.id;
    try {
      if (messageText.trim() === "" && !messageFile) {
        return;
      }
      const formData = new FormData();
      if (!messageFile) {
        formData.append("content", messageText);
      }
      if (messageFile) {
        formData.append("image", messageFile);
      }
      const response = await axios.post(
        `${API_URL}direct_messages/${currentUserId}/`,
        formData,
        {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
            "content-type": "multipart/form-data",
          },
        }
      );
      setMessageText("");
      setMessageFile(null);
    } catch (error) {
      console.error("Error sending message:", error);
      // Handle the error as needed
    }
  };

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;

    // Check if the user has scrolled up manually
    if (!isUserScrolledUp && messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [messages, isUserScrolledUp]);

  const handleScroll = () => {
    const messagesContainer = messagesContainerRef.current;

    // Check if the user has scrolled up manually
    setIsUserScrolledUp(
      messagesContainer.scrollTop <
        messagesContainer.scrollHeight - messagesContainer.clientHeight
    );
  };

  return (
    <MDBContainer fluid className="py-5" style={{ backgroundColor: "#000000" }}>
      <MDBRow>
        <MDBCol md="12">
          <MDBCard id="chat3" style={{ borderRadius: "15px" }}>
            <MDBCardBody>
              <MDBRow>
                <MDBCol md="3" lg="5" xl="4" className="mb-4 mb-md-0">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link>Inbox</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <MDBTypography listUnStyled className="mb-0">
                    {chats.map((chat) => (
                      <li
                        key={chat?.id}
                        onClick={() => handleViewMessage(chat?.id)}
                        className="p-2 border-bottom"
                      >
                        <a href="#!" className="d-flex justify-content-between">
                          <div className="d-flex flex-row">
                            <div>
                              <img
                                src={chat?.profile_picture1}
                                style={{
                                  width: "45px",
                                  height: "45px",
                                  borderRadius: "10rem",
                                }}
                                alt="avatar"
                                className="d-flex align-self-center me-3"
                              />
                              <span
                                className={`badge ${chat?.availability_status} badge-dot`}
                              ></span>
                            </div>
                            <div className="pt-1">
                              <p className="fw-bold mb-0">{chat?.username}</p>
                            </div>
                          </div>
                          <div className="pt-1">
                            <p className="small text-muted mb-1">
                              {/* {chat?.sexuality} */}
                            </p>
                            <span className="badge bg-danger rounded-pill float-end">
                              {chat?.unread_count}
                            </span>
                          </div>
                        </a>
                      </li>
                    ))}
                  </MDBTypography>
                </MDBCol>

                <MDBCol md="9" lg="7" xl="8">
                  <div style={{ textAlign: "center" }}>
                    {/* <h3>Group Name</h3> */}
                  </div>

                  <div
                    ref={messagesContainerRef}
                    onScroll={handleScroll}
                    className="p-3 scroll"
                    style={{ height: "50vh", overflowY: "auto" }}
                  >
                    {messages.map((messages) => (
                      <div
                        key={messages?.id}
                        className={`d-flex flex-row ${
                          messages?.sender === profile?.id
                            ? "justify-content-end"
                            : "justify-content-start"
                        }`}
                      >
                        <img
                          src={messages?.sender_profile_image}
                          alt="avatar"
                          style={{
                            width: "45px",
                            height: "45px",
                            borderRadius: "10rem",
                          }}
                        />
                        <div>
                          <p
                            className={`small p-2 ms-3 mb-1 rounded-3 ${
                              messages?.sender === profile?.id
                                ? "bg-primary text-white"
                                : "bg-light"
                            }`}
                          >
                            {messages?.chat_image ? (
                              <img
                                src={messages?.chat_image}
                                style={{ height: "20vh", width: "100%" }}
                                alt="image"
                              />
                            ) : (
                              <div>{messages?.content}</div>
                            )}
                          </p>
                          <p
                            className={`small ms-3 mb-3 rounded-3 text-muted ${
                              messages?.sender === profile?.id
                                ? "float-end"
                                : ""
                            }`}
                          >
                            {new Date(messages?.timestamp).toLocaleString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                              }
                            )}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <form encType="multipart/form-data">
                    <div className="text-muted d-flex justify-content-start align-items-center pe-3 pt-3 mt-2">
                      <img
                        src={profile?.profile_picture1}
                        alt="avatar 3"
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "6rem",
                        }}
                      />
                      {messageFile ? (
                        <>
                          <span className="ms-2">
                            {messageFile.name} uploaded!
                          </span>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm ms-2"
                            onClick={() => {
                              setMessageFile(null); // Clear the selected file
                            }}
                          >
                            X
                          </button>
                        </>
                      ) : (
                        <>
                          <textarea
                            className="form-control form-control-lg"
                            id="exampleFormControlInput2"
                            placeholder="Type message"
                            value={messageText}
                            onChange={handleMessageChange}
                            rows={1} // You can adjust the number of rows as needed
                          ></textarea>
                          <label
                            htmlFor="fileInput"
                            className="ms-1 text-muted"
                          >
                            <MDBIcon fas icon="paperclip" />
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            className="form-control form-control-lg ms-1"
                            id="fileInput"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                        </>
                      )}
                      <a
                        className="ms-3"
                        href="#!"
                        onClick={() => {
                          handleSendMessage();
                          setMessageText(""); // Clear the message input
                        }}
                      >
                        <MDBIcon fas icon="paper-plane" />
                      </a>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
