import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import API_URL from "../../api/config";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useAdminAuthNavigation } from "../../Component/CheckLocalStorage";


const CreateTerms = () => {
  useAdminAuthNavigation()
    const navigate = useNavigate()
  const [content, setContent] = useState("");
  const [version, setVersion] = useState("");
  const [error, setError] = useState("");

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const handleVersionChange = (e) => {
    setVersion(e.target.value);
  };

  const handleCreateTerms = async () => {
    // Validate input
    if (!content.trim() || !version.trim()) {
      setError("Content and version are required");
      toast.error("Content and version are required");
      return;
    }

    try {
      const response = await fetch(`${API_URL}terms/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content: content,
          version: version,
        }),
      });

      if (response.ok) {
        // Clear form and error on success
        setContent("");
        setVersion("");
        setError("");
        toast.success("Terms created successfully");
      } else {
        toast.error("Failed to create terms");
      }
    } catch (error) {
        toast.error("Error during create terms:", error);
    }
  };

  const handleViewTerms = () => {
    navigate("/terms")
  }

  return (
    <Container className="mt-4">
        <ToastContainer/>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Card>
            <Card.Body>
              <Card.Title>Create Terms and Conditions</Card.Title>
              <Form>
             

                <div class="form-group" >
                  <label class="col-lg-6 control-label">Version</label>
                  <div class="col-lg-12">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Enter version"
                      value={version}
                      onChange={handleVersionChange}
                    />
                  </div>
                </div>

                <div style={{ gap: "1rem" }}>
                  <div class="form-group" style={{ width: "100%" }}>
                    <label class="col-lg-6 control-label">Content</label>
                    <div class="col-lg-12">
                      <textarea
                        className="form-control mb-4"
                        rows="5"
                        type="text"
                        placeholder="Enter content"
                        name="content"
                        value={content}
                        onChange={handleContentChange}
                      ></textarea>
                    </div>
                  </div>
                </div>

             

                {error && <p style={{ color: "red" }}>{error}</p>}

                <Button variant="primary" onClick={handleCreateTerms}>
                  Create Terms
                </Button>
                {" "}
                <Button variant="primary" onClick={handleViewTerms}>
                 View Terms
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateTerms;
