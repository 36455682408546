import React from "react";
import { useState, useEffect } from "react";
import { Container, Nav, Navbar, Button, Form } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import axios from "axios";
import logo from "../components/images/logo.png";
import API_URL from "../api/config";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [USER_DETAILS, setUserDetails] = useState([]);
  const AUTH_TOKEN = localStorage.getItem("netfronttoken");
  const localrole = localStorage.getItem("netfrontsexuality");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if netfronttoken is available in localStorage
    const authToken = localStorage.getItem("netfronttoken");
    const role = localStorage.getItem("netfrontrole");
    const interest = localStorage.getItem("netfrontsexuality");

    if (authToken && role && interest) {
      // If netfronttoken is available, set the login status to true
      setIsLoggedIn(true);
    } else if ((authToken && !role) || (authToken && !interest)) {
      localStorage.setItem("netfrontrole", USER_DETAILS?.role);
      localStorage.setItem(
        "netfrontsexuality",
        USER_DETAILS?.sexuality || "nothing"
      );
    } else {
      clearLocalStorage();
      setIsLoggedIn(false);
    }
  }, [USER_DETAILS]);

  // Function to clear localStorage
  const clearLocalStorage = () => {
    localStorage.removeItem("netfronttoken");
    localStorage.removeItem("netfrontrole");
    localStorage.removeItem("netfrontsexuality");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}update-profile/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });
        setUserDetails(response.data);
      } catch (error) {}
    };

    fetchData();
  }, [AUTH_TOKEN, USER_DETAILS]);

  const handleImageClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting user location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
    }
  }, []);

  useEffect(() => {
    const updateLocation = async () => {
      if (latitude !== null && longitude !== null) {
        try {
          const response = await axios.patch(
            `${API_URL}update_location/${latitude}/${longitude}/`,
            {},
            {
              headers: {
                Authorization: `Token ${AUTH_TOKEN}`,
              },
            }
          );
        } catch (error) {
          console.error("Error updating location:", error);
        }
      }
    };

    updateLocation();
  }, [latitude, longitude, AUTH_TOKEN]);

  const handleLogout = async () => {
    localStorage.removeItem("netfronttoken");
    localStorage.removeItem("netfrontrole");
    localStorage.removeItem("netfrontsexuality");
    setIsLoggedIn(false);
    navigate("/login");
    try {
      await axios.post(
        `${API_URL}logout/`,
        {},
        {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        }
      );

      // Clear local storage and navigate to the login page
      localStorage.removeItem("netfronttoken");
      localStorage.removeItem("netfrontrole");
      localStorage.removeItem("netfrontsexuality");
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const checkUserRole = (allowedRoles, userRole) => {
    // Check if the user's role is in the list of allowed roles
    if (!allowedRoles.includes(userRole)) {
      console.error("Unauthorized access. Redirecting to home.");
      navigate("/");
    }
  };

  useEffect(() => {
    // Check user role for specific pages
    if (isLoggedIn) {
      const userRole = USER_DETAILS?.role;

      switch (window.location.pathname) {
        case "/admin-page":
          // Only allow admins to access this page
          checkUserRole(["admin"], userRole);
          break;
        // Add more cases for other pages as needed
        default:
          break;
      }
    }
  }, [USER_DETAILS, isLoggedIn]);

  // alert(localrole)

  return (
    <div>
      <Navbar data-bs-theme="dark" expand="lg" className="bg-body-tertiary">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              {/* netflings */}
              <img src={logo} style={{ width: "120px", height: "90px" }} />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="navbarScroll" />
          {isLoggedIn ? (
            <Navbar.Collapse id="navbarScroll" style={{ margin: "auto" }}>
              <Nav
                className="ms-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                {USER_DETAILS?.role === "others" && (
                  <>
                    <LinkContainer to="/">
                      <Nav.Link> Home</Nav.Link>
                    </LinkContainer>

                    <LinkContainer to="/message">
                      <Nav.Link> Inbox</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/groupmessage">
                      <Nav.Link> Group </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/profile">
                      <Nav.Link>My Profile</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/refer">
                      <Nav.Link>Refer</Nav.Link>
                    </LinkContainer>
                  </>
                )}

                {USER_DETAILS?.role === "admin" && (
                  <>
                    <LinkContainer to="/quickaccess">
                      <Nav.Link> All Users </Nav.Link>
                    </LinkContainer>

                    <NavDropdown title="User Control" id="basic-nav-dropdown">
                      <LinkContainer to="/request">
                        <NavDropdown.Item> Request </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/reports">
                        <NavDropdown.Item> Reports </NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                    <NavDropdown title="Withdrawals" id="basic-nav-dropdown">
                      <LinkContainer to="/withdrawal">
                        <NavDropdown.Item> Request </NavDropdown.Item>
                        {/* <Nav.Link> Top Earners </Nav.Link> */}
                      </LinkContainer>
                      <LinkContainer to="/topearners">
                        <NavDropdown.Item> Top Earners </NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>

                    <NavDropdown title="More" id="basic-nav-dropdown">
                      <LinkContainer to="/banner">
                        <NavDropdown.Item> Banners </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/chatcontrol">
                        <NavDropdown.Item> Chats </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/createterms">
                        <NavDropdown.Item> Terms of Use </NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                  </>
                )}

                {localrole == "nothing" && (
                  <>
                    <LinkContainer to="/profile">
                      <Nav.Link>Verify Profile</Nav.Link>
                    </LinkContainer>
                  </>
                )}

                {/* <div
                  style={{ display: "flex", flexDirection: "row", gap: "1px" }}
                  className="ms-auto"
                > */}
                <LinkContainer to="/logout">
                  <Nav.Link onClick={handleLogout}>
                    <span style={{ color: "white" }}>Logout</span>
                  </Nav.Link>
                </LinkContainer>
                {/* </div> */}
              </Nav>
            </Navbar.Collapse>
          ) : (
            <Navbar.Collapse id="navbarScroll" style={{ margin: "auto" }}>
              <Nav
                className="ms-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <LinkContainer to="/Login">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/register">
                  <Nav.Link>Register</Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          )}

          {/* {isLoggedIn ? (
            USER_DETAILS?.role === "others" ? (
              <Navbar.Collapse id="navbarScroll" style={{ margin: "auto" }}>
                <Nav
                  className="ms-auto  my-2 my-lg-0"
                  style={{ maxHeight: "100px" }}
                  navbarScroll
                >
                  <LinkContainer to="/">
                    <Nav.Link> Home</Nav.Link>
                  </LinkContainer>

                  <LinkContainer to="/message">
                    <Nav.Link> Inbox</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/groupmessage">
                    <Nav.Link> Group </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/profile">
                    <Nav.Link>My Profile</Nav.Link>
                  </LinkContainer>

                  <LinkContainer to="/Logout">
                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                  </LinkContainer>
                </Nav>
              </Navbar.Collapse>
            ) : USER_DETAILS?.role === "admin" ? (
              <Navbar.Collapse id="navbarScroll" style={{ margin: "auto" }}>
                <Nav
                  className="me-auto  my-2 my-lg-0"
                  style={{ maxHeight: "100px" }}
                  navbarScroll
                >
                  <LinkContainer to="/quickaccess">
                    <Nav.Link> All Users </Nav.Link>
                  </LinkContainer>

                  <LinkContainer to="/request">
                    <Nav.Link> Request </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/reports">
                    <Nav.Link> Reports </Nav.Link>
                  </LinkContainer>

                  <LinkContainer to="/banner">
                    <Nav.Link> Banners </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/chatcontrol">
                    <Nav.Link> Chats </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/createterms">
                    <Nav.Link> Terms of Use </Nav.Link>
                  </LinkContainer>
                </Nav>

                <div
                  style={{ display: "flex", flexDirection: "row", gap: "1px" }}
                  className="ms-auto"
                >
                  <LinkContainer to="/logout">
                    <Nav.Link onClick={handleLogout}>
                      {" "}
                      <span style={{ color: "white" }}> Logout </span>
                    </Nav.Link>
                  </LinkContainer>
                </div>
              </Navbar.Collapse>
            ) : localrole === "nothing" ? (
              <Navbar.Collapse>
                <Nav>
                  <LinkContainer to="/profile">
                    <Nav.Link>Verify Profile</Nav.Link>
                  </LinkContainer>
                </Nav>
              </Navbar.Collapse>
            ) : null
          ) : (
            <Navbar.Collapse id="navbarScroll" style={{ margin: "auto" }}>
              <Nav
                className="ms-auto  my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <LinkContainer to="/Login">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>

                <LinkContainer to="/register">
                  <Nav.Link>Register</Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          )} */}
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
