import React, { useState } from "react";
import {
  MDBContainer,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBRow,
} from "mdb-react-ui-kit";
import { Nav, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import API_URL from "../../api/config";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

export default function AdminLogin() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state

  const handleusernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

 
  const handleLogin = async () => {
    try {
      setLoading(true); // Set loading to true when starting the login request

      const response = await axios.post(
        `${API_URL}login/`,
        {
          username: username,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        localStorage.setItem("netfronttoken", data?.token);
        // localStorage.setItem("netfrontsexuality", data?.sexuality);
        // localStorage.setItem("netfrontgender", data?.gender);
        if(data?.role === "admin"){
          localStorage.setItem("netfrontrole", data?.role);
          localStorage.setItem("netfronttoken", data?.token);
          localStorage.setItem("netfrontsexuality", data?.sexuality || "admin");
          toast.success("Login successful");
          // Update the isLoggedIn state to true upon successful login
          navigate("/quickaccess");
        } else{
          toast.error("Not an Admin");
        }
      } else {
        toast.error("Login failed");
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error(error?.response?.data?.detail || "Error");
    } finally {
      setLoading(false); // Set loading to false after the login request is completed
    }
  };

  return (
    <MDBContainer
      fluid
      className="d-flex align-items-center justify-content-center bg-image"
      style={{
        backgroundImage: "url(https://wallpapercave.com/wp/wp3261228.jpg)",
      }}
    >
      <ToastContainer />
      <div className="mask gradient-custom-3"></div>
      <MDBCard className="m-3" style={{ maxWidth: "" }}>
        <MDBCardBody className="px-5">
          <MDBRow>
            <MDBCol size="12">
              <h2 className="text-uppercase text-center mb-5">Login As Admin</h2>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    wrapperClass="mb-4"
                    // id="formControlDefault"
                    type="username"
                    value={username}
                    onChange={handleusernameChange}
                    placeholder="username"
                  />
                  <MDBInput
                    wrapperClass="mb-4"
                    id="formControlDefault"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Password"
                  />
                </MDBCol>
    
                <div>
              
                  <Button
                    className={`mb-4 w-100 gradient-custom-4 ${
                      loading ? "disabled" : ""
                    }`}
                    onClick={handleLogin}
                    disabled={loading} // Disable the button when loading is true
                  >
                    {loading ? "Logging in..." : "Login"}
                  </Button>
              
                </div>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
}
