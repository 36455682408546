import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Webcam from "react-webcam";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import API_URL from "../../api/config";
import { useAuthNavigation } from "../../Component/CheckLocalStorage";

const Profiles = () => {
  useAuthNavigation()
  const AUTH_TOKEN = localStorage.getItem("netfronttoken");
  const navigate = useNavigate();

  //   Where users details is stored once fetched
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const [selfieTaken, setSelfieTaken] = useState(false);

  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    age: 0,
    price: 0,
    gender: "",
    sexuality: "",
    availability: "",
    bio: "",
    identity_document: "",
    profile_picture1:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ3Sz275KZaTeZyS-thzhD6U9VIkfO8c-WPA&usqp=CAU",
    profile_picture2:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ3Sz275KZaTeZyS-thzhD6U9VIkfO8c-WPA&usqp=CAU",
    profile_picture3:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ3Sz275KZaTeZyS-thzhD6U9VIkfO8c-WPA&usqp=CAU",
  });

  const openVerificationModal = (e) => {
    e.preventDefault();
    setShowVerificationModal(true);
    setSelfieTaken(false);
  };

  const closeVerificationModal = () => {
    setShowVerificationModal(false);
    setCapturedImage(null);
  };

  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      console.log("Captured Image Source:", imageSrc);
      setCapturedImage(imageSrc);
      setSelfieTaken(true);
    } else {
      console.log("Webcam reference not available.");
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const uploadVerification = () => {
    if (!capturedImage) {
      console.error("No captured image to upload.");
      return;
    }
    // Convert capturedImage to Blob
    const blob = dataURItoBlob(capturedImage);

    // Create a file with a valid extension (e.g., "image.png")
    const file = new File([blob], "image.jpeg", { type: "image/jpeg" });
    // setIdentityDocument(dataURItoBlob(capturedImage));
    // setIdentityDocument(file);
    setProfileData((prevData) => ({
      ...prevData,
      identity_document: file,
    }));

    console.log("Image uploaded successfully.", {
      capturedImage,
      profileData,
    });
    closeVerificationModal();
  };

  useEffect(() => {
    // Fetch data from the endpoint using Axios
    axios
      .get(`${API_URL}update-profile/`, {
        headers: {
          Authorization: `Token ${AUTH_TOKEN}`,
        },
      })
      .then((response) => {
        const profileDataFromApi = response?.data;
        if (
          profileDataFromApi?.first_name != "" &&
          profileDataFromApi?.last_name != "" &&
          profileDataFromApi?.sexuality != null &&
          profileDataFromApi?.gender != null &&
          profileDataFromApi?.age != "0"
        ) {
          setDisable(true);
        }
        // Update the state with the fetched data
        setProfileData((prevData) => ({
          ...prevData,
          first_name: profileDataFromApi?.first_name || "",
          last_name: profileDataFromApi?.last_name || "",
          email: profileDataFromApi?.email || "",
          phone: profileDataFromApi?.phone || "",
          gender: profileDataFromApi?.gender || "",
          sexuality: profileDataFromApi?.sexuality || "",
          availability: profileDataFromApi?.availability_status || "",
          age: profileDataFromApi?.age || "",
          price: profileDataFromApi?.price || "",
          identity_document: "", // Set this to empty initially
          bio: profileDataFromApi?.bio || "",
          bio: profileDataFromApi?.bio || "",
          profile_picture1:
            profileDataFromApi?.profile_picture1 ||
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ3Sz275KZaTeZyS-thzhD6U9VIkfO8c-WPA&usqp=CAU",
          profile_picture2:
            profileDataFromApi?.profile_picture2 ||
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ3Sz275KZaTeZyS-thzhD6U9VIkfO8c-WPA&usqp=CAU",
          profile_picture3:
            profileDataFromApi?.profile_picture3 ||
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ3Sz275KZaTeZyS-thzhD6U9VIkfO8c-WPA&usqp=CAU",
        }));
        console.log({ response });
        console.log({ AUTH_TOKEN });
        console.log({ profileData });
      })
      .catch((error) => console.error("Error fetching profile data:", error));
  }, [AUTH_TOKEN]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      // For demonstration purposes, let's set the image directly (remove this in your actual implementation)
      const imageUrl = URL.createObjectURL(file);
      setProfileData((prevData) => ({
        ...prevData,
        [`profile_picture${index}`]: imageUrl,
      }));
    }
  };

  const urlToBlob = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  };

  // Update handleUpdate function
  const handleUpdate = async (e) => {
    e.preventDefault();

    // Log the values to the console
    console.log("Profile Data:", profileData);
    const requiredFields = [
      "first_name",
      "last_name",
      "gender",
      "sexuality",
      "age",
    ];
    const missingField = requiredFields.find((field) => !profileData[field]);
    if (missingField) {
      // Display an error message indicating the missing field
      toast.error(`Please fill in the "${missingField}" field.`);
      return;
    }
    if (!/^\d+(\.\d{1,2})?$/.test(profileData.price)) {
      toast.error("Price must be a valid figure");
      return;
    }
    if (profileData.age < 18) {
      toast.error("Must be older than 18");
      return;
    }

    const formData = new FormData();

    // Append each field from profileData to the FormData object
    for (const [key, value] of Object.entries(profileData)) {
      // Skip items with values of " ", null, or "international"
      if (
        value === " " ||
        value === "" ||
        value === 0 ||
        value === "0" ||
        value === null ||
        value ===
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ3Sz275KZaTeZyS-thzhD6U9VIkfO8c-WPA&usqp=CAU" ||
        (typeof value === "string" &&
          value.startsWith("https://netflingandchill-bucket.s3.amazonaws.com"))
      ) {
        continue;
      }
      // "https://netflingandchill-bucket.s3.amazonaws.com/profiles/image_7mZTn71.jpeg"

      // If the value is a blob URL, convert it to Blob and then append it using the field name
      if (typeof value === "string" && value.startsWith("blob:")) {
        const blob = await urlToBlob(value);
        formData.append(key, blob, "image.jpeg");
      } else if (value !== null && value !== undefined) {
        // Only append regular fields that are not null or undefined
        formData.append(key, value);
      } else {
        // Otherwise, append regular fields
        formData.append(key, value);
      }
    }

    // Placeholder for API request to update profile data (replace this with your actual API endpoint)
    setLoading(true);
    axios
      .put(`${API_URL}update-profile/`, formData, {
        headers: {
          Authorization: `Token ${AUTH_TOKEN}`,
          "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
        },
      })
      .then((response) => {
        // Handle the response as needed
        localStorage.setItem("netfrontsexuality", response?.data?.sexuality);
        console.log("Profile updated successfully:", response.data);
        toast.success("Profile updated successfully");
        navigate("/")
      })
      .catch((error) => {
        // Handle errors
        console.error("Error updating profile:", error);
        toast.error("Error updating profile. Please try again.");
      }).finally(() => {
        // Stop loading, regardless of success or error
        setLoading(false);
      });
  };

  //   const handleUpdate = (e) => {
  //     e.preventDefault();

  //     // Log the values to the console
  //     console.log("Profile Data:", profileData);
  //     const requiredFields = ["phone", "price", "availability", "bio"];
  //     const missingField = requiredFields.find((field) => !profileData[field]);
  //     if (missingField) {
  //       // Display an error message indicating the missing field
  //       toast.error(`Please fill in the "${missingField}" field.`);
  //       return;
  //     }
  //     if (!/^\d+(\.\d{1,2})?$/.test(profileData.price)) {
  //       toast.error("Price must be a valid figure");
  //       return;
  //     }
  //     if (profileData.age < 18) {
  //       toast.error("Must be older than 18");
  //       return;
  //     }

  //     const formData = new FormData();

  //   // Append each field from profileData to the FormData object
  //   Object.entries(profileData).forEach(([key, value]) => {
  //     // If the value is a File object (from an image input), append it using the field name
  //     if (value instanceof File) {
  //       formData.append(key, value);
  //     } else {
  //       // Otherwise, append regular fields
  //       formData.append(key, value);
  //     }
  //   });

  //   // Placeholder for API request to update profile data (replace this with your actual API endpoint)
  //   axios
  //     .put(`${API_URL}update-profile/`, formData, {
  //       headers: {
  //         Authorization: `Token ${AUTH_TOKEN}`,
  //         'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
  //       },
  //     })
  //     .then((response) => {
  //       // Handle the response as needed
  //       console.log('Profile updated successfully:', response.data);
  //       toast.success('Profile updated successfully');
  //     })
  //     .catch((error) => {
  //       // Handle errors
  //       console.error('Error updating profile:', error);
  //       toast.error('Error updating profile. Please try again.');
  //     });
  //   };

  return (
    <div>
      {/* <Modal show={false} onHide={() => {}}>
        <Modal.Header closeButton>
          <Modal.Title>Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {false ? (
            <div>
              <img
                src=""
                alt="Captured Verification"
                className="img-fluid"
                style={{ border: "solid green", width: "100%" }}
              />
              <Button
                variant="primary"
                style={{ marginTop: "10px" }}
                onClick={() => {}}
              >
                Upload as Verification
              </Button>
            </div>
          ) : (
            <div>
              <Webcam
                audio={false}
                screenshotFormat="image/jpeg"
                className="webcam-preview"
                style={{ border: "solid red", width: "100%" }}
              />
              <Button variant="primary" onClick={() => {}}>
                Take Picture
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal> */}

      <Modal show={showVerificationModal} onHide={closeVerificationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {capturedImage ? (
            <div>
              <img
                src={capturedImage}
                alt="Captured Verification"
                className="img-fluid"
                style={{ border: "solid green", width: "100%" }}
              />
              <Button
                variant="primary"
                style={{ marginTop: "10px" }}
                onClick={uploadVerification}
              >
                Upload as Verification
              </Button>
            </div>
          ) : (
            <div>
              <Webcam
                audio={false}
                // ref={(webcam) => setWebcamRef(webcam)}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className="webcam-preview"
                style={{ border: "solid red", width: "100%" }}
              />
              <Button variant="primary" onClick={captureImage}>
                Take Picture
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <ToastContainer />

      <a
        className={`btn btn-dark my-3 ${false ? "disabled" : ""}`}
        onClick={() => {}}
      >
        {false ? "Subscribed" : "Subscribe Now"}
      </a>

      {/* Subscription Modal */}
      <Modal show={false} onHide={() => {}}>
        <Modal.Header closeButton>
          <Modal.Title>Subscribe Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => {}}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your bank name"
                name="username"
                value=""
                onChange={() => {}}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                value=""
                onChange={() => {}}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter the amount"
                name="amount"
                value=""
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Subscribe
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <div>
        <div class="container bootstrap snippets bootdey">
          <h1 class="text-primary">Edit Profile</h1>
          <hr />
          <div className="row">
            {/* <div className="col-md-3">
              <div className="text-center">
                {[
                  profileData.profile_picture1,
                  profileData.profile_picture2,
                  profileData.profile_picture3,
                ].map((item, index) => (
                  <div key={index}>
                    <img
                      src={item}
                      className="avatar img-circle img-thumbnail"
                      alt={`avatar ${index + 1}`}
                      style={{ minHeight: "5rem", maxHeight: "5rem" }}
                    />
                    <h6>Profile Image{index + 1}</h6>
                    <input
                      type="file"
                      className="form-control"
                      style={{ margin: "0.5rem 0" }}
                      accept="image/*"
                      onChange={() => {}}
                    />
                  </div>
                ))}
              </div>
            </div> */}

            <div className="col-md-3">
              <div className="text-center">
                {[1, 2, 3].map((index) => (
                  <div key={index}>
                    <img
                      src={profileData[`profile_picture${index}`]}
                      className="avatar img-circle img-thumbnail"
                      alt={`avatar ${index}`}
                      style={{ minHeight: "5rem", maxHeight: "5rem" }}
                    />
                    <h6>Profile Image{index}</h6>
                    <input
                      type="file"
                      className="form-control"
                      style={{ margin: "0.5rem 0" }}
                      accept="image/*"
                      onChange={(e) => handleImageChange(e, index)}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div class="col-md-9 personal-info">
              <h3>Personal info</h3>

              <form class="form-horizontal" role="form">
                {/* Line 1 */}
                <div className="d-flex" style={{ gap: "1rem" }}>
                  <div class="form-group" style={{ width: "50%" }}>
                    <label class="col-lg-6 control-label">First name:</label>
                    <div class="col-lg-12">
                      <input
                        class="form-control"
                        type="text"
                        name="first_name"
                        value={profileData?.first_name}
                        onChange={handleChange}
                        disabled={disable}
                      />
                    </div>
                  </div>
                  <div class="form-group" style={{ width: "50%" }}>
                    <label class="col-lg-6 control-label">Last name:</label>
                    <div class="col-lg-12">
                      <input
                        class="form-control"
                        type="text"
                        name="last_name"
                        value={profileData?.last_name}
                        onChange={handleChange}
                        disabled={disable}
                      />
                    </div>
                  </div>
                </div>

                {/* Line 2 */}
                <div className="d-flex" style={{ gap: "1rem" }}>
                  <div class="form-group" style={{ width: "50%" }}>
                    <label class="col-lg-6 control-label">Email:</label>
                    <div class="col-lg-12">
                      <input
                        class="form-control"
                        type="email"
                        name="email"
                        value={profileData?.email}
                        onChange={handleChange}
                        disabled={disable}
                      />
                    </div>
                  </div>
                  <div class="form-group" style={{ width: "50%" }}>
                    <label class="col-lg-6 control-label">Phone:</label>
                    <div class="col-lg-12">
                      <input
                        class="form-control"
                        type="number"
                        name="phone"
                        value={profileData?.phone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                {/* Line 3 */}
                <div className="d-flex" style={{ gap: "1rem" }}>
                  <div class="form-group" style={{ width: "50%" }}>
                    <label class="col-lg-6 control-label">Age:</label>
                    <div class="col-lg-12">
                      <input
                        class="form-control"
                        type="number"
                        name="age"
                        value={profileData?.age}
                        placeholder="18+"
                        onChange={handleChange}
                        min={18}
                        disabled={disable}
                      />
                    </div>
                  </div>
                  <div class="form-group" style={{ width: "50%" }}>
                    <label class="col-lg-6 control-label">Price(₦):</label>
                    <div class="col-lg-12">
                      <input
                        class="form-control"
                        type="number"
                        placeholder="e.g 50000"
                        value={profileData?.price}
                        name="price"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                {/* Line 4 */}
                <div className="d-flex" style={{ gap: "1rem" }}>
                  <div class="form-group" style={{ width: "50%" }}>
                    <label class="col-lg-12 control-label">Gender:</label>
                    <div class="col-lg-12">
                      <div class="ui-select">
                        <select
                          id="user_time_zone"
                          class="form-control"
                          name="gender"
                          value={profileData?.gender}
                          onChange={handleChange}
                          disabled={disable}
                        >
                          <option>Select </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" style={{ width: "50%" }}>
                    <label class="col-lg-6 control-label">Sexuality:</label>
                    <div class="col-lg-12">
                      <div class="ui-select">
                        <select
                          id="user_time_zone"
                          class="form-control"
                          name="sexuality"
                          value={profileData?.sexuality}
                          onChange={handleChange}
                          disabled={disable}
                        >
                          <option>Select </option>

                          <option value="lesbian">Lesbian</option>
                          <option value="gay">Gay</option>
                          <option value="bisexual">Bisexual</option>
                          <option value="heterosexual">Heterosexual</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Line 5 */}
                <div className="d-flex" style={{ gap: "1rem" }}>
                  <div class="form-group" style={{ width: "50%" }}>
                    <label class="col-lg-6 control-label">Availabilty:</label>
                    <div class="col-lg-12">
                      <div class="ui-select">
                        <select
                          id="user_time_zone"
                          class="form-control"
                          name="availability"
                          value={profileData?.availability}
                          onChange={handleChange}
                        >
                          <option>Select </option>
                          <option value="available">Available </option>
                          <option value="booked">Booked</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" style={{ width: "50%" }}>
                    <label class="col-lg-6 control-label">
                      Verification Doc:
                    </label>
                    <div class="col-lg-11">
                      {/* <a
                        className="btn btn-primary"
                        onClick={openVerificationModal}
                        disabled={disable}
                      >
                        Take a Selfie
                      </a> */}
                      <button
                        className={`btn btn-primary ${
                          selfieTaken ? "disabled" : ""
                        }`}
                        onClick={openVerificationModal}
                        disabled={selfieTaken}
                      >
                        {selfieTaken ? "Taken" : "Take a Selfie"}
                      </button>
                    </div>
                  </div>
                </div>

                {/* Line 7 */}
                <div style={{ gap: "1rem" }}>
                  <div class="form-group" style={{ width: "100%" }}>
                    <label class="col-lg-6 control-label">Bio:</label>
                    <div class="col-lg-12">
                      <textarea
                        className="form-control mb-4"
                        rows="5"
                        placeholder="Enter your bio..."
                        name="bio"
                        value={profileData.bio}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                </div>

                {/* UPDATE PROFILE BUTTON */}

                <button
                  className="btn btn-primary"
                  onClick={handleUpdate}
                  disabled={loading}
                >
                  {loading ? "Updating..." : "Update Profile"}{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Profiles;
