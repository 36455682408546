import React, { useState, useEffect } from 'react';
import { Table, Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import axios from 'axios';
import API_URL from '../../api/config';
import { useAdminAuthNavigation } from '../../Component/CheckLocalStorage';

function ReportedUsers() {
  useAdminAuthNavigation()
  const AUTH_TOKEN = localStorage.getItem('netfronttoken');
  const [users, setUsers] = useState([]);
  const [selectedSexuality, setSelectedSexuality] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}get_reported_users/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
          params: {
            query: searchTerm,
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchData();
  }, [AUTH_TOKEN, searchTerm]);

  const handleSexualityChange = (e) => {
    setSelectedSexuality(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };



  const handleToggleVerification = async (reported_user) => {
    try {
      await axios.post(`${API_URL}toggle_user/${reported_user}/`, null, {
        headers: {
          Authorization: `Token ${AUTH_TOKEN}`,
        },
      });
      // Refresh the user data after toggling verification
      // fetchData();
      window.location.reload();
    } catch (error) {
      console.error('Error toggling verification:', error);
    }
  };

  return (
    <>
      <h1 className="text-center">Reported Users</h1>
     

     

      <Table striped bordered hover responsive style={{ textAlign: 'center' }}>
        <thead>
          <tr>
            <th>#</th>
            <th>Username</th>
            <th>Reason</th>
            <th>Disable</th>
          </tr>
        </thead>
        <tbody>
          {users?.map((user, index) => (
            <tr key={index}>
              <td> {index + 1} </td>
              <td>{user?.reported_user_username }</td>
              <td>{user?.reason}</td>
              <td>
                <Form.Check
                  type="switch"
                  id={`flexSwitchCheckDefault-${index}`}
                  checked={user?.is_verified}
                  onChange={() => handleToggleVerification(user?.reported_user)}
                />
              </td>
          
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default ReportedUsers;
