// import React, { useState } from "react";
// import {
//   MDBContainer,
//   MDBCol,
//   MDBCard,
//   MDBCardBody,
//   MDBInput,
//   MDBBtn,
//   MDBRow,
// } from "mdb-react-ui-kit";
// import { Nav, Button } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";
// import API_URL from "../api/config";
// import "react-toastify/dist/ReactToastify.css";
// import { ToastContainer, toast } from "react-toastify";
// import axios from "axios";

// export default function ForgottenPassword() {
//   const [email, setEmail] = useState("");
//   const [loading, setLoading] = useState(false); // Add loading state

//   const handlePasswordChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handleForgottenPassword = async () => {
//     try {
//       setLoading(true); // Set loading to true on button click
//       const response = await axios.post(`${API_URL}password_reset/`, { email });
//       toast.success("Check Mail For Magic Link");
//     } catch (error) {
//       toast.error(error?.response?.data?.details || "Error");
//     } finally {
//       setLoading(false); // Set loading back to false when the request is complete
//     }
//   };

//   return (
//     <MDBContainer
//       fluid
//       className="d-flex align-items-center justify-content-center bg-image"
//       style={{
//         backgroundImage: "url(https://wallpapercave.com/wp/wp3261228.jpg)",
//       }}
//     >
//       <ToastContainer />
//       <div className="mask gradient-custom-3"></div>
//       <MDBCard className="m-3" style={{ maxWidth: "" }}>
//         <MDBCardBody className="px-5">
//           <MDBRow>
//             <MDBCol size="12">
//               <h2 className="text-uppercase text-center mb-5">
//                 Forgotten Password
//               </h2>
//               <MDBRow>
//                 <MDBCol>
//                   <MDBInput
//                     wrapperClass="mb-4"
//                     id="formControlDefault"
//                     type="email"
//                     value={email}
//                     onChange={handlePasswordChange}
//                     placeholder="Input email "
//                   />
//                 </MDBCol>
//                 <div>
//                   <Button
//                     className="mb-4 w-100 gradient-custom-4"
//                     onClick={handleForgottenPassword}
//                     disabled={loading} // Disable the button when loading
//                   >
//                     {loading ? "Resetting..." : "Reset"}
//                   </Button>
//                 </div>
//               </MDBRow>
//             </MDBCol>
//           </MDBRow>
//         </MDBCardBody>
//       </MDBCard>
//     </MDBContainer>
//   );
// }

import React, { useState } from "react";
import {
  MDBContainer,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBRow,
} from "mdb-react-ui-kit";
import { Nav, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import API_URL from "../api/config";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

export default function ForgottenPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  const handlePasswordChange = (e) => {
    setEmail(e.target.value);
  };

  const handleForgottenPassword = () => {
    setLoading(true); // Set loading to true on button click

    axios
      .post(`${API_URL}password_reset/`, { email })
      .then((response) => {
        toast.success("Check Mail For Magic Link");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.detail || "Error");
      })
      .finally(() => {
        setLoading(false); // Set loading back to false when the request is complete
      });
  };

  return (
    <MDBContainer
      fluid
      className="d-flex align-items-center justify-content-center bg-image"
      style={{
        backgroundImage: "url(https://wallpapercave.com/wp/wp3261228.jpg)",
      }}
    >
      <ToastContainer />
      <div className="mask gradient-custom-3"></div>
      <MDBCard className="m-3" style={{ maxWidth: "" }}>
        <MDBCardBody className="px-5">
          <MDBRow>
            <MDBCol size="12">
              <h2 className="text-uppercase text-center mb-5">
                Forgotten Password
              </h2>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    wrapperClass="mb-4"
                    id="formControlDefault"
                    type="email"
                    value={email}
                    onChange={handlePasswordChange}
                    placeholder="Input email "
                  />
                </MDBCol>
                <div>
                  <Button
                    className="mb-4 w-100 gradient-custom-4"
                    onClick={handleForgottenPassword}
                    disabled={loading} // Disable the button when loading
                  >
                    {loading ? "Resetting..." : "Reset"}
                  </Button>
                </div>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
}

