import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { Container } from "react-bootstrap";

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import React from "react";

import BabeScreen from "./components/screens/BabeScreen";
import SelectedUserScreen from "./components/screens/SelectedUserScreen";

import Registration from "./components/Registration";
import Login from "./components/Login";
import Message from "./components/Message";
import GroupMessage from "./components/GroupMessages";
import Profiles from "./components/screens/Profiles";

// import LesbianScreen from "./components/screens/LesbianScreen";
// import GayScreen from "./components/screens/GayScreen";
// import BisexualScreen from "./components/screens/BisexualScreen";
// import HeterosexualScreen from "./components/screens/Heterosexual";

import Profile from "./components/screens/Profile";
import ForgottenPassword from "./components/ForgottenPassword";
import Terms from "./components/TermsAndCondition";
import PasswordResetConfirm from "./components/ConfirmPassword";

import AdminLogin from "./components/admin/login";
import QuickAccess from "./components/admin/QuickAccess";
import ReportedUsers from "./components/admin/ReportedUsers";

import UserRequest from "./components/admin/UserRequest";
import Banner from "./components/Banner";
import CreateBanner from "./components/admin/CreateBanner";

import AdminChatControl from "./components/admin/ChatControl";
import CreateTerms from "./components/admin/CreateTerms";

import LandingPage from "./components/screens/Home";
import ReferralDashboard from "./components/screens/Referral";
import WithdrawalRequests from "./components/admin/Withdrawal";
import TopEarners from "./components/admin/TopEarners";


function App() {



  return (
    <Router>
      <Header />
      {/* {shouldRenderBanner && <Banner />} */}
      <Banner />

      <main className="py-3">
        <Container>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route
              exact
              path="/resetpassword"
              element={<ForgottenPassword />}
            />
            <Route exact path="/register" element={<Registration />} />
            <Route
              path="/password_reset_confirm/:uidb64/:token/"
              element={<PasswordResetConfirm />}
            />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/home" element={<LandingPage />} />

            <Route exact path="/" element={<BabeScreen />} />
            <Route exact path="/user/:id" element={<SelectedUserScreen />} />
            <Route exact path="/message" element={<Message />} />
            
            <Route exact path="/refer" element={<ReferralDashboard />} />
            <Route exact path="/groupmessage" element={<GroupMessage />} />
            {/* <Route exact path="/profiles" element={<Profile />} /> */}
            <Route exact path="/profile" element={<Profiles />} />

            {/* <Route exact path="/lesbian" element={<LesbianScreen />} />
            <Route exact path="/gay" element={<GayScreen />} />
            <Route exact path="/bisexual" element={<BisexualScreen />} />
            <Route
              exact
              path="/heterosexual"
              element={<HeterosexualScreen />}
            /> */}

            {/* Admin */}
            <Route exact path="/admin" element={<AdminLogin />} />
            <Route exact path="/quickaccess" element={<QuickAccess />} />
            <Route exact path="/reports" element={<ReportedUsers />} />

            <Route exact path="/request" element={<UserRequest />} />
            <Route exact path="/banner" element={<CreateBanner />} />
            <Route exact path="/chatcontrol" element={<AdminChatControl />} />

            <Route exact path="/withdrawal" element={<WithdrawalRequests />} />
            <Route exact path="/createterms" element={<CreateTerms />} />
            <Route exact path="/topearners" element={<TopEarners />} />
          </Routes>
        </Container>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
