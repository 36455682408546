import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import API_URL from "../../api/config";
import { useAdminAuthNavigation } from "../../Component/CheckLocalStorage";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

function WithdrawalRequests() {
  useAdminAuthNavigation();
  const AUTH_TOKEN = localStorage.getItem("netfronttoken");
  const [withdrawalList, setWithdrawalList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMap, setLoadingMap] = useState({});

  useEffect(() => {
    // Fetch withdrawal details
    const fetchWithdrawalDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}withdrawals/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });

        // Update withdrawalList state with the retrieved data
        setWithdrawalList(response.data);
        console.log({ response });
      } catch (error) {
        // Handle error
        console.error("Error fetching withdrawal details:", error);
      }
    };

    // Call the fetchWithdrawalDetails function
    fetchWithdrawalDetails();
  }, [AUTH_TOKEN]);

  const handleMarkPaid = (withdrawalId) => {
    // Set loading state for the specific withdrawalId
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      [withdrawalId]: true,
    }));
    axios
      .post(`${API_URL}withdrawals/${withdrawalId}/mark_paid/`, null, {
        headers: {
          Authorization: `Token ${AUTH_TOKEN}`,
        },
      })
      .then((data) => {
        toast.success("Paid");
        window.location.reload()       
      })
      .catch((err) => {
        toast.error(err?.response?.data?.detail || "Failed");
        console.log({ err });
        
      })
      .finally(() => {
        // Reset loading state for the specific withdrawalId
        setLoadingMap((prevLoadingMap) => ({
          ...prevLoadingMap,
          [withdrawalId]: false,
        }));
      });
  };

  const pendingPayment = (withdrawalList || []).filter(
    (user) => user?.paid === !true || user?.paid === false
  );

  return (
    <>
      <ToastContainer />
      <h1 className="text-center">Withdrawal Requests</h1>
      <Table striped bordered hover responsive style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Username</th>
            <th>Bank Name</th>
            <th>Account Number</th>
            <th>Amount</th>
            <th>Verified</th>
            <th>Paid Out</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {pendingPayment.map((request, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                {request?.user?.first_name + " " + request?.user?.first_name}
              </td>
              <td>{request?.user?.username}</td>
              <td>{request?.bank_name}</td>
              <td>{request?.bank_account_number}</td>
              <td>{request?.points_to_withdraw}</td>
              <td>{request?.user?.is_verified ? "Yes" : "No"}</td>
              <td>{request?.paid ? "Yes" : "No"}</td>
              <td>
                <Button
                  variant="info"
                  onClick={() => handleMarkPaid(request?.id)}
                  disabled={loadingMap[request?.id]}
                >
                {loadingMap[request?.id] ? "Loading..." : "Paid"}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default WithdrawalRequests;
