import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useAuthNavigation = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem("netfronttoken");
    const role = localStorage.getItem("netfrontrole");
    const interest = localStorage.getItem("netfrontsexuality");

    if (!authToken || !role || !interest) {
      navigate("/home");
    }
  }, [navigate]);
};
export const useAdminAuthNavigation = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem("netfronttoken");
    const role = localStorage.getItem("netfrontrole");
    const interest = localStorage.getItem("netfrontsexuality");

    if (!authToken || role != "admin" || !interest) {
      navigate("/home");
    }
  }, [navigate]);
};
