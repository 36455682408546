import React, { useState, useEffect,  } from 'react';
import { Table, Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import API_URL from '../../api/config';
import { useAdminAuthNavigation } from '../../Component/CheckLocalStorage';

function QuickAccess() {
  useAdminAuthNavigation()
  const AUTH_TOKEN = localStorage.getItem('netfronttoken');
  const [users, setUsers] = useState([]);
  const navigate = useNavigate()
  const [selectedSexuality, setSelectedSexuality] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}search-profiles/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
          params: {
            query: searchTerm,
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchData();
  }, [AUTH_TOKEN, searchTerm]);

  const handleSexualityChange = (e) => {
    setSelectedSexuality(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredUsers =
    selectedSexuality === 'All'
      ? users?.profiles
      : users?.profiles?.filter((user) => user?.sexuality === selectedSexuality);

  const verifiedUsers = filteredUsers?.filter((user) => user?.is_verified === true);

  const handleToggleVerification = async (userId) => {
    try {
      await axios.post(`${API_URL}toggle_user/${userId}/`, null, {
        headers: {
          Authorization: `Token ${AUTH_TOKEN}`,
        },
      });
      // Refresh the user data after toggling verification
      navigate("/request")
      // window.location.reload();
    } catch (error) {
      console.error('Error toggling verification:', error);
    }
  };

  return (
    <>
      <h1 className="text-center">Users</h1>
      <div style={{ width: '10%' }} className="mb-3">
        <Form.Select
          aria-label="Default select example"
          onChange={handleSexualityChange}
          value={selectedSexuality}
        >
          <option value="All">All</option>
          <option value="lesbian">Lesbian</option>
          <option value="gay">Gay</option>
          <option value="bisexual">Bisexual</option>
          <option value="heterosexual">Heterosexual</option>
        </Form.Select>
      </div>

      <InputGroup className="mb-3">
        <FormControl
          placeholder="Search by First Name or Email or Username or Last Name"
          aria-label="Search by Name"
          onChange={handleSearchChange}
        />
      </InputGroup>

      <Table striped bordered hover responsive style={{ textAlign: 'center' }}>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Gender</th>
            <th>Interest</th>
            <th>Status</th>
            <th>Username</th>
            <th>Verification</th>
          </tr>
        </thead>
        <tbody>
          {verifiedUsers?.map((user, index) => (
            <tr key={index}>
              <td> {index + 1} </td>
              <td>{user?.first_name + ' ' + user?.last_name}</td>
              <td>{user?.gender}</td>
              <td>{user?.sexuality}</td>
              <td>{user?.availability_status}</td>
              <td>{user?.username}</td>

              <td>
                <Form.Check
                  type="switch"
                  id={`flexSwitchCheckDefault-${index}`}
                  checked={user?.is_verified}
                  onChange={() => handleToggleVerification(user?.id)}
                />
              </td>

            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default QuickAccess;
