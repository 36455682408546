import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import API_URL from '../api/config';

const Terms = () => {
  const [termsData, setTermsData] = useState(null);
 

  useEffect(() => {
    const fetchTermsData = async () => {
      try {
        const response =  await fetch(`${API_URL}terms/`);
        if (response.ok) {
          const data = await response.json();
          setTermsData(data);
        } else {
          console.error('Failed to fetch terms data');
        }
      } catch (error) {
        console.error('Error during fetch:', error);
      }
    };

    fetchTermsData();
  }, []);

  return (
    <Container className="mt-4">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Card>
            <Card.Body>
              <Card.Title>Terms and Conditions</Card.Title>
              {termsData ? (
                <>
                  {/* <Card.Text>ID: {termsData.id}</Card.Text> */}
                  <Card.Text>Content: {termsData.content}</Card.Text>
                  <Card.Text>Version: {termsData.version}</Card.Text>
                  {/* <Card.Text>Date Created: {termsData.date_created}</Card.Text> */}
                </>
              ) : (
                <p>Loading terms data...</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Terms;
