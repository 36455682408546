// PasswordResetConfirm.jsx
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBRow,
} from "mdb-react-ui-kit";
import { Nav, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import API_URL from "../api/config";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const PasswordResetConfirm = () => {
  const { uidb64, token } = useParams();
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleResetConfirm = async () => {
    try {
      const response = await axios.post(
        `${API_URL}password_reset_confirm/${uidb64}/${token}/`,
        { password: password,
        confirmpassword: password }
      );
      setMessage(response.data.detail);
    } catch (error) {
      setMessage("Error confirming password reset.");
    }
  };

  return (
    <MDBContainer
      fluid
      className="d-flex align-items-center justify-content-center bg-image"
      style={{
        backgroundImage: "url(https://wallpapercave.com/wp/wp3261228.jpg)",
      }}
    >
      <ToastContainer />
      <div className="mask gradient-custom-3"></div>
      <MDBCard className="m-3" style={{ maxWidth: "" }}>
        <MDBCardBody className="px-5">
          <MDBRow>
            <MDBCol size="12">
              <h2 className="text-uppercase text-center mb-5">
                Input New Password
              </h2>
              <MDBRow>
                <MDBCol>
                  {/* <div>
                    <input
                      type="password"
                      placeholder="Enter your new password"
                    />
                    <button >
                      Confirm Password Reset
                    </button>
                    <p>{message}</p>
                  </div> */}
                  <MDBInput
                    wrapperClass="mb-4"
                    id="formControlDefault"
                    type="text"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Input New Password "
                  />
                </MDBCol>
                <p>{message}</p>
                <div>
                  <Button
                    className="mb-4 w-100 gradient-custom-4"
                    onClick={handleResetConfirm}
                  >
                    Confirm
                  </Button>
                </div>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
};

export default PasswordResetConfirm;
