// import React, { useState, useEffect } from "react";
// import Carousel from "react-bootstrap/Carousel";
// import axios from "axios";
// import Image from "./images/yellow-bg-ads.jpg";
// import API_URL from "../api/config";

// function Banner() {
//   const [announcements, setAnnouncements] = useState([]);
//   const userSexuality = localStorage.getItem("netfrontsexuality");
//   const AUTH_TOKEN = localStorage.getItem("netfronttoken");
//   const [USER_DETAILS, setUserDetails] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${API_URL}update-profile/`, {
//           headers: {
//             Authorization: `Token ${AUTH_TOKEN}`,
//           },
//         });
//         setUserDetails(response.data);
//       } catch (error) {}
//     };

//     fetchData();
//   }, [AUTH_TOKEN, USER_DETAILS]);

//   useEffect(() => {
//     // Fetch announcements
//     const fetchAnnouncements = async () => {
//       try {
//         const response = await axios.get(`${API_URL}announcements/`);
//         setAnnouncements(response?.data?.announcements);
//       } catch (error) {
//         console.error("Error fetching announcements:", error);
//       }
//     };

//     fetchAnnouncements();
//   }, []);

//   const filteredAnnouncements = announcements.filter(
//     (announcement) =>
//       announcement.is_global ||
//       announcement.target_sexuality === USER_DETAILS?.sexuality ||
//       announcement.target_sexuality === "All" ||
//       announcement.target_sexuality === "all"
//   );

//   return (
//     <div style={{ margin: "0 10vw" }}>
//       <Carousel style={{ height: "20vh", marginTop: "1rem" }}>
//         {filteredAnnouncements.map((announcement) => (
//           <Carousel.Item key={announcement.id}>
//             <img
//               src={Image}
//               alt={`Announcement ${announcement.id}`}
//               style={{ height: "20vh", width: "100%", borderRadius: "10px" }}
//             />
//             <Carousel.Caption>
//               <h3>{announcement.content}</h3>
//               <p></p>
//             </Carousel.Caption>
//           </Carousel.Item>
//         ))}
//       </Carousel>
//     </div>
//   );
// }

// export default Banner;

import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import Image from "./images/yellow-bg-ads.jpg";
import API_URL from "../api/config";

function Banner() {
  const [announcements, setAnnouncements] = useState([]);
  const userSexuality = localStorage.getItem("netfrontsexuality");
  const AUTH_TOKEN = localStorage.getItem("netfronttoken");
  const [USER_DETAILS, setUserDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}update-profile/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });
        setUserDetails(response.data);
      } catch (error) {}
    };

    fetchData();
  }, [AUTH_TOKEN, USER_DETAILS]);

  useEffect(() => {
    // Fetch announcements
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get(`${API_URL}announcements/`);
        setAnnouncements(response?.data?.announcements);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchAnnouncements();
  }, []);

  const filteredAnnouncements = announcements.filter(
    (announcement) =>
      announcement.is_global ||
      announcement.target_sexuality === USER_DETAILS?.sexuality ||
      announcement.target_sexuality === "All" ||
      announcement.target_sexuality === "all"
  );

  // Return null if there are no filtered announcements
  if (filteredAnnouncements.length === 0) {
    return null;
  }

  return (
    <div style={{ margin: "0 10vw" }}>
      <Carousel style={{ height: "20vh", marginTop: "1rem" }}>
        {filteredAnnouncements.map((announcement) => (
          <Carousel.Item key={announcement.id}>
            <img
              src={Image}
              alt={`Announcement ${announcement.id}`}
              style={{ height: "20vh", width: "100%", borderRadius: "10px" }}
            />
            <Carousel.Caption>
              <h3>{announcement.content}</h3>
              <p></p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default Banner;
