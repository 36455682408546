import React, { useState, useEffect } from "react";
import axios from "axios";
import API_URL from "../../api/config";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useAuthNavigation } from "../../Component/CheckLocalStorage";


const ReferralDashboard = () => {
  useAuthNavigation()

  // State for referral information
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [userDetails, setUserDetails] = useState([]);
  const AUTH_TOKEN = localStorage.getItem("netfronttoken");
  const [withdrawalLoading, setWithdrawalLoading] = useState(false);
  const [withdrawalError, setWithdrawalError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [availableBalance, setAvailableBalance] = useState(0);






  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}update-profile/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });
        console.log({response})
        setUserDetails(response.data);
        console.log({userDetails})
      } catch (error) {
        // setError(error);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, [AUTH_TOKEN]);

  
  useEffect(() => {
    // Update available balance when user details change
    setAvailableBalance(userDetails?.points_balance || 0);
  }, [userDetails]);

  const banksInNigeria = [
    'Access Bank',
    'Zenith Bank',
    'Guaranty Trust Bank (GTB)',
    'First Bank of Nigeria',
    'United Bank for Africa (UBA)',
    'Fidelity Bank',
    'Sterling Bank',
    'Union Bank',
    'Ecobank Nigeria',
    // Add more banks as needed
  ];



  // State for withdrawal form
  const [withdrawalForm, setWithdrawalForm] = useState({
    name: "",
    bank: "",
    accountNumber: "",
    amount: 0,
  });

  // Function to handle withdrawal form input changes
  const handleWithdrawalFormChange = (e) => {
    const { name, value } = e.target;
    setWithdrawalForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

 

  const handleWithdrawal = () => {
    // Validate withdrawal amount
    
    if (withdrawalForm.amount <= 0) {
      toast.error("Input a value greater that 0");
      return;
    }
    if (withdrawalForm.amount <= 14) {
      toast.error("Minimun Withdrawal is 15points");
      return;
    }
    if (withdrawalForm.amount > availableBalance) {
      toast.error("Withdrawal amount exceeds available balance");
      return;
    }

  
    // Set loading state
    setWithdrawalLoading(true);
  
    // Prepare withdrawal data
    const withdrawalData = {
      points_to_withdraw: withdrawalForm.amount,
      bank_account_number: withdrawalForm.accountNumber,
      bank_name: withdrawalForm.bank,
    };
  
    // Make POST request to withdrawal endpoint
    setLoading(true)

    axios.post(`${API_URL}withdrawal/`, withdrawalData, {
      headers: {
        Authorization: `Token ${AUTH_TOKEN}`,
      },
    })
      .then((response) => {
        // Handle success
        toast.success("Withdrawal successful!");
  
        // Clear withdrawal form after handling withdrawal
        setWithdrawalForm({
          name: "",
          bank: "",
          accountNumber: "",
          amount: 0,
        });
  
        // Reset loading state
        setWithdrawalLoading(false);
        setLoading(false)
  
        // Reload the page or perform additional actions
        window.location.reload();
      })
      .catch((error) => {
        // Handle error
        setWithdrawalError(error.response?.data?.detail || "Withdrawal failed");
        toast.error(withdrawalError);
        setLoading(false)
        // Reset loading state
        setWithdrawalLoading(false);
      });
  };
  

  // Function to copy referral link to clipboard
  const copyReferralLink = () => {
    // Copy to clipboard logic using navigator.clipboard.writeText()
    navigator.clipboard
      .writeText(userDetails?.referral_code)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Unable to copy to clipboard");
      });
  };

  const inputStyle = {
    width: "200px", // Adjust the width as needed
    padding: "10px",
    fontSize: "16px",
    border: "2px solid #3498db",
    borderRadius: "5px",
    textAlign: "center",
    color: "#2c3e50",
    fontWeight: "bold",
    margin: "auto",
    marginTop: "20px", // Add spacing from the top
  };

  return (
    <Container fluid>
      <ToastContainer />

      <Row>
        <Col style={{ marginTop: "2rem" }}>
          <h2>How It Works</h2>
          <Alert variant="info">
            Share your referral link with friends. Earn rewards for each
            successful referral. Withdraw your earnings to your bank account.
          </Alert>
        </Col>
      </Row>

      <Row>
        <Col>
          <h2>Your Referral Link</h2>
          {/* <h2>{userDetails?.referral_count} Referrals</h2>
          <h2> Reffered by: {userDetails?.referred_by}</h2> */}
          <div className="mb-3">
            <input
              type="text"
              value={userDetails?.referral_code}
              readOnly
              style={inputStyle}
            />
            <Button variant="primary" onClick={copyReferralLink}>
              Copy Link
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <h2>Balance</h2>
          <Row>
            <Col>
              <p>
                {" "}
                <strong style={{ fontWeight: "700" }}>
                  {" "}
                  Balance:
                </strong>{" "}
                {userDetails?.points_balance}
                point(s)
              </p>
            </Col>
            <Col>
              <p>
                {" "}
                <strong style={{ fontWeight: "700" }}>
                  {" "}
                  Pending Payment:
                </strong>{" "}
               
                {userDetails?.points_pending_withdrawal}
                point(s)
              </p>
            </Col>
            <Col>
              <p>
                {" "}
                <strong style={{ fontWeight: "700" }}>
                  {" "}
                  Total Paid:
                </strong>{" "}
                {userDetails?.points_withdrawn}
                point(s)
              </p>
            </Col>
            <Col>
              <p>
                {" "}
                
                <strong style={{ fontWeight: "700" }}>Total Earned:</strong>
                {userDetails?.points_accrued}
                 point(s)
              </p>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <h2>Withdrawal</h2>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Total Referred</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                name="name"
                value={`Toatal Referred: ${userDetails?.referral_count || 0} `}
                disabled
              />
            </Form.Group>

            <Form.Group controlId="formBank">
              <Form.Label>Select Bank</Form.Label>
              {/* TODO: Replace with actual list of banks */}
              <Form.Control
                as="select"
                name="bank"
                value={withdrawalForm.bank}
                onChange={handleWithdrawalFormChange}
              >
                <option value="">Select Bank</option>
                {banksInNigeria.map((bank, index) => (
                  <option key={index} value={bank}>
                    {bank}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formAccountNumber">
              <Form.Label>Bank Account Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter account number"
                name="accountNumber"
                value={withdrawalForm.accountNumber}
                onChange={handleWithdrawalFormChange}
              />
            </Form.Group>

            <Form.Group controlId="formAmount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter withdrawal amount"
                name="amount"
                value={withdrawalForm.amount}
                onChange={handleWithdrawalFormChange}
              />
            </Form.Group>

            <Button
              variant="primary"
              style={{ marginTop: "1rem" }}
              onClick={handleWithdrawal}
              disabled={loading}
            >
               {loading ? "Loading..." : "Withdraw"}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ReferralDashboard;
