import React, { useState, useEffect } from "react";
import { Row, Col, Card, Spinner, Alert } from "react-bootstrap";
import axios from "axios";
import API_URL from "../../api/config";
import VerificationCard from "../../Component/UnVerified";
import UpdateCard from "../../Component/UnUpdate";
import { Link, useNavigate } from "react-router-dom";


function BabeScreen() {
  const navigate = useNavigate();
  const [babeList, setBabeList] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const AUTH_TOKEN = localStorage.getItem("netfronttoken");
  const [USER_DETAILS, setUserDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}update-profile/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });
        setUserDetails(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [AUTH_TOKEN]);

  useEffect(() => {
    const updateLocation = async () => {
      if (latitude !== null && longitude !== null) {
        try {
          const response = await axios.patch(
            `${API_URL}update_location/${latitude}/${longitude}/`,
            {},
            {
              headers: {
                Authorization: `Token ${AUTH_TOKEN}`,
              },
            }
          );
        } catch (error) {
          console.error("Error updating location:", error);

        }
      }
    };

    updateLocation();
  }, [latitude, longitude, AUTH_TOKEN]);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting user location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
    }
  }, []);

  useEffect(() => {
    if (latitude !== null && longitude !== null) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${API_URL}get_profiles_within_radius/${latitude}/${longitude}/`,
            {
              headers: {
                Authorization: `Token ${AUTH_TOKEN}`,
              },
            }
          );
          setBabeList(response.data);
        } catch (error) {
          setError(error);
          console.log({ error });
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [latitude, longitude, AUTH_TOKEN]);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status" variant="primary" />
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    navigate("/home");
    console.log({ error });
    return (
      <Alert variant="danger" className="text-center mt-5">
        {error.message}
      </Alert>
    );
  }

  const shouldDisplayBabeList =
    USER_DETAILS?.sexuality !== null 

  return (
    <div>
      {/* Check verification status and render the appropriate card */}
      {localStorage.getItem("netfrontsexuality") === "nothing" && (
        <UpdateCard />
      )}
      {USER_DETAILS?.is_verified === false && <VerificationCard />}

      {shouldDisplayBabeList && (
        <>
          <h1 className="text-center">
            
          </h1>

          <Row>
            {babeList
              .filter((babe) => {
                if (
                  USER_DETAILS?.gender === "male" &&
                  USER_DETAILS?.sexuality === "bisexual"
                ) {
                  return (
                    babe?.sexuality === "gay" || babe?.sexuality === "lesbian"
                  );
                } else if (
                  USER_DETAILS?.gender === "female" &&
                  USER_DETAILS?.sexuality === "bisexual"
                ) {
                  return (
                    babe?.sexuality === "gay" || babe?.sexuality === "lesbian"
                  );
                } else if (
                  USER_DETAILS?.gender === "male" &&
                  USER_DETAILS?.sexuality === "gay"
                ) {
                  return babe?.sexuality === "gay";
                } else if (
                  USER_DETAILS?.gender === "female" &&
                  USER_DETAILS?.sexuality === "lesbian"
                ) {
                  return babe?.sexuality === "lesbian";
                } else if (
                  USER_DETAILS?.gender === "male" &&
                  USER_DETAILS?.sexuality === "heterosexual"
                ) {
                  return (
                    babe?.gender === "female" &&
                    babe?.sexuality === "heterosexual"
                  );
                } else if (
                  USER_DETAILS?.gender === "female" &&
                  USER_DETAILS?.sexuality === "heterosexual"
                ) {
                  return (
                    babe?.gender === "male" &&
                    babe?.sexuality === "heterosexual"
                  );
                }
              })
              .map((babe) => (
                <Col key={babe._id} sm={12} md={6} lg={4} xl={3}>
                  {/* Your Card component */}
                  <Card className="my-3 p-2 rounded">
                    <Link to={`../user/${babe?.id}`}>
                      <img
                        src={babe?.profile_picture1}
                        style={{
                          width: "100%",
                          height: "12rem",
                          objectFit: "cover",
                        }}
                        alt={babe?.username}
                      />
                    </Link>
                    <Card.Body>
                      <Link to={`../user/${babe?.id}`}>
                        <Card.Title as="div">
                          <strong style={{ fontWeight: "bold" }}>
                            {babe?.username}
                          </strong>
                        </Card.Title>
                      </Link>
                   
                      <p>{babe?.bio}</p>

                    
                    </Card.Body>
                  </Card>
                  {/* End of your Card component */}
                </Col>
              ))}

            {babeList.filter((babe) => {
                if (
                  USER_DETAILS?.gender === "male" &&
                  USER_DETAILS?.sexuality === "bisexual"
                ) {
                  return (
                    babe?.sexuality === "gay" || babe?.sexuality === "lesbian"
                  );
                } else if (
                  USER_DETAILS?.gender === "female" &&
                  USER_DETAILS?.sexuality === "bisexual"
                ) {
                  return (
                    babe?.sexuality === "gay" || babe?.sexuality === "lesbian"
                  );
                } else if (
                  USER_DETAILS?.gender === "male" &&
                  USER_DETAILS?.sexuality === "gay"
                ) {
                  return babe?.sexuality === "gay";
                } else if (
                  USER_DETAILS?.gender === "female" &&
                  USER_DETAILS?.sexuality === "lesbian"
                ) {
                  return babe?.sexuality === "lesbian";
                } else if (
                  USER_DETAILS?.gender === "male" &&
                  USER_DETAILS?.sexuality === "heterosexual"
                ) {
                  return (
                    babe?.gender === "female" &&
                    babe?.sexuality === "heterosexual"
                  );
                } else if (
                  USER_DETAILS?.gender === "female" &&
                  USER_DETAILS?.sexuality === "heterosexual"
                ) {
                  return (
                    babe?.gender === "male" &&
                    babe?.sexuality === "heterosexual"
                  );
                }
              }).length === 0 && (
              <Col sm={12} className="text-center mt-4">
                <p>No Nearby User found.</p>
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
}

export default BabeScreen;
