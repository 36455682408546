import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import API_URL from "../../api/config";
import { useAdminAuthNavigation } from '../../Component/CheckLocalStorage';

const CreateBanner = () => {
  useAdminAuthNavigation()
  const [announcements, setAnnouncements] = useState([]);
  const [newAnnouncementContent, setNewAnnouncementContent] = useState('');
  const [newAnnouncementSexuality, setNewAnnouncementSexuality] = useState('all');
  const AUTH_TOKEN = localStorage.getItem("netfronttoken");

  useEffect(() => {
    // Fetch all announcements
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get(`${API_URL}announcements/`, {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        });
        setAnnouncements(response.data?.announcements);
      } catch (error) {
        console.error('Error fetching announcements:', error);
      }
    };

    fetchAnnouncements();
  }, []);

  const handleCreateAnnouncement = async () => {
    try {
      await axios.post(
        `${API_URL}announcements/`,
        {
          content: newAnnouncementContent,
          target_sexuality: newAnnouncementSexuality,

        },
        {
          headers: {
            Authorization: `Token ${AUTH_TOKEN}`,
          },
        }
      );
      // After creating the announcement, fetch the updated list of announcements
      window.location.reload()
    } catch (error) {
      console.error('Error creating announcement:', error);
    }
  };

  const handleDeleteAnnouncement = async (announcementId) => {
    try {
      await axios.delete(`${API_URL}announcements/${announcementId}/`, {
        headers: {
          Authorization: `Token ${AUTH_TOKEN}`,
        },
      });
      // After deleting the announcement, fetch the updated list of announcements
      window.location.reload()
    } catch (error) {
      console.error('Error deleting announcement:', error);
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <h2>Create Announcement</h2>
          <Form>
            <Form.Group controlId="formAnnouncementContent">
              <Form.Label>Content</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter announcement content"
                value={newAnnouncementContent}
                onChange={(e) => setNewAnnouncementContent(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formAnnouncementSexuality">
              <Form.Label>Target Sexuality</Form.Label>
              <Form.Control
                as="select"
                value={newAnnouncementSexuality}
                onChange={(e) => setNewAnnouncementSexuality(e.target.value)}
              >
              <option>Select Category</option>
              <option value="All">All</option>
              <option value="lesbian">Lesbian</option>
              <option value="gay">Gay</option>
              <option value="bisexual">Bisexual</option>
              <option value="heterosexual">Heterosexual</option>
              </Form.Control>
            </Form.Group>

            {/* <Form.Group controlId="formAnnouncementIsGlobal">
              <Form.Check
                type="checkbox"
                label="Is Global"
                checked={newAnnouncementIsGlobal}
                onChange={(e) => setNewAnnouncementIsGlobal(e.target.checked)}
              />
            </Form.Group> */}

            <Button variant="primary" onClick={handleCreateAnnouncement}>
              Create Announcement
            </Button>
          </Form>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h2>Announcement List</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Content</th>
                <th>Target Sexuality</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {announcements.map((announcement) => (
                <tr key={announcement.id}>
                  <td>{announcement.content}</td>
                  <td>{announcement.target_sexuality}</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteAnnouncement(announcement.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateBanner;
