import React, { useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { Form, Nav, Button, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import API_URL from "../api/config";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { FaBeer } from "react-icons/fa";

function Registration() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [referral, setReferral] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleReferralChange = (e) => {
    setReferral(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleCheckboxChange = () => {
    setAgreedToTerms(!agreedToTerms);
  };

  const handleRegister = () => {
    if (!agreedToTerms) {
      toast.error("You must agree to the terms of service to register.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email format.");
      return;
    }

    const usernameRegex = /^[a-zA-Z0-9_]{3,20}$/;
    if (!usernameRegex.test(username)) {
      toast.error(
        "Invalid username format (3-20 characters, alphanumeric or underscore only)."
      );
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    setLoading(true);

    axios
      .post(
        `${API_URL}register/`,
        {
          username: username.toLowerCase(),
          email: email.toLowerCase(),
          password: password,
          agree_terms: agreedToTerms,
          referral_code: referral
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success("Registration successful");
        localStorage.setItem("netfronttoken", response?.data?.token);
        localStorage.setItem("netfrontrole", response?.data?.role);
        localStorage.setItem("netfrontsexuality", response?.data?.sexuality || "nothing");


        navigate("/");
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error(error?.response?.data?.detail || "Error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MDBContainer
      fluid
      className="d-flex align-items-center justify-content-center bg-image"
      style={{
        backgroundImage: "url(https://wallpapercave.com/wp/wp3261228.jpg)",
      }}
    >
      <ToastContainer />
      <div className="mask gradient-custom-3"></div>

      <MDBCard className="m-3" style={{ maxWidth: "" }}>
        <MDBCardBody className="px-5">
          <MDBRow>
            <MDBCol size="12">
              <h2 className="text-uppercase text-center mb-5">
                Register an Account
              </h2>
              <MDBRow>
                <MDBCol md="6">
                  <label>Username</label>
                  <MDBInput
                    wrapperClass="mb-4"
                    type="text"
                    placeholder="username"
                    value={username}
                    onChange={handleUsernameChange}
                  />
                </MDBCol>

                <MDBCol md="6">
                  <label>Email</label>
                  <MDBInput
                    wrapperClass="mb-4"
                    id="formControlDefault"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="email"
                  />
                </MDBCol>
                <MDBCol md="12">
                  <label>Referal Code</label>
                  <MDBInput
                    wrapperClass="mb-4"
                    id="formControlDefault"
                    type="text"
                    value={referral}
                    onChange={handleReferralChange}
                    placeholder="optional"
                  />
                </MDBCol>

                <Col md="6">
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <div className="password-input-wrapper">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Password"
                      />
                      <span
                        className="password-toggle-icon"
                        onClick={toggleShowPassword}
                        style={{
                          fontWeight: 800,
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                      >
                        {showPassword ? <>Hide</> : <>Show</>}
                      </span>
                    </div>
                  </Form.Group>
                </Col>

                <Col md="6" style={{ marginTop: "0.4rem" }}>
                  <Form.Group>
                    <Form.Label>Confirm Password</Form.Label>
                    <div className="password-input-wrapper">
                      <Form.Control
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        placeholder="Confirm Password"
                      />
                      <span
                        className="password-toggle-icon"
                        onClick={toggleShowConfirmPassword}
                        style={{
                          fontWeight: 800,
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                      >
                        {showConfirmPassword ? <>Hide</> : <>Show</>}
                      </span>
                    </div>
                  </Form.Group>
                </Col>

                {/* <MDBCol md="6">
                  <label>Confirm Password</label>
                  <div className="password-input-wrapper">
                    <MDBInput
                      wrapperClass="mb-4"
                      id="formControlDefault"
                      type={showConfirmPassword ? "text" : "password"}
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      placeholder="Confirm Password"
                    />
                    {showConfirmPassword ? (
                      <FaBeer onClick={toggleShowConfirmPassword} />
                    ) : (
                      <FaBeer onClick={toggleShowConfirmPassword} />
                    )}
                  </div>
                </MDBCol> */}

                <div style={{ marginTop: "1rem" }}>
                  <div className="d-flex flex-row justify-content-center mb-4">
                    <MDBCheckbox
                      name="flexCheck"
                      id="flexCheckDefault"
                      onChange={handleCheckboxChange}
                      style={{ margin: "0px 0.5rem" }}
                    />

                    <p>
                      I agree to the{" "}
                      <span>
                        <a
                          href="/terms"
                          style={{ color: "black", fontWeight: 800 }}
                        >
                          Terms and Condition
                        </a>
                      </span>{" "}
                      of Netflingandchill and that{" "}
                      <span
                        style={{
                          fontWeight: "800",
                        }}
                      >
                        I am also over the age of 18
                      </span>
                    </p>
                  </div>
                  <Button
                    className="mb-4 w-100 gradient-custom-4"
                    onClick={handleRegister}
                    disabled={loading}
                  >
                    {loading ? "Registering..." : "Register"}
                  </Button>
                </div>
                <div className="d-flex flex-row justify-content-center mb-4">
                  <p>
                    Already have an account?{" "}
                    <a
                      href="/login"
                      style={{ color: "black", fontWeight: 800 }}
                    >
                      {" "}
                      Login{" "}
                    </a>
                  </p>
                </div>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
}

export default Registration;
